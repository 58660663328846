import { AllowedAccountListItem, AccountInvitationDto } from '@just-ai/api/dist/generated/Accountsadmin';
import { AppLogger } from '@just-ai/logger';
import { signal } from '@preact/signals-react';
import { AxiosError } from 'axios';

import { ccOptions, goToSelectAccount, useCloudLogin, useKeycloakLogin } from '../../api/cc.api';
import apiClient, { hasDemo, isDemoMode } from '../../api/client';
import { mockUser } from '../../api/constants';
import { isAlpha } from '../../isAlpha';
import { JustSessionUserData, OrganizationData } from '../../types/currentUser';

export type PaymentPollResponse = {
  pendingRequests: number;
  successfulOperations: boolean;
  failures: boolean;
};

export const currentUser = signal<(JustSessionUserData & OrganizationData) | undefined>(undefined);
export const currentUserAllowedAccounts = signal<AllowedAccountListItem[] | undefined>(undefined);
export const currentUserInvitations = signal<AccountInvitationDto[] | undefined>(undefined);

export const setCurrentUser = (newCurrentUser: JustSessionUserData & OrganizationData) => {
  currentUser.value = newCurrentUser;
};

export const utmTagsList = signal<Record<string, string>>({});

export const hasFeature = (featureName: string) => {
  if (isAlpha) return false;
  if (useKeycloakLogin.value) return false;
  return Boolean(currentUser.value?.features?.some(feature => feature === featureName));
};

export const hasPermission = (permissionName: string) => {
  if (isAlpha) return true;
  return Boolean(currentUser.value?.permissions?.some(perm => perm === permissionName));
};

export const getAllowedAccounts = async (userId: number) => {
  try {
    if (!ccOptions.value?.domains) return;
    const domainData = Object.values(ccOptions.value?.domains).find(
      domain => domain.domain === window.location.hostname
    );
    const { data } = await apiClient.get<AllowedAccountListItem[]>(
      `/api/accountsadmin/c/users/${userId}/allowed-accounts?product=${domainData?.product}`
    );
    currentUserAllowedAccounts.value = data;
  } catch (e) {
    if ((e as AxiosError).code) {
      AppLogger.error({
        message: `Error getting allowed accounts`,
        exception: e as AxiosError,
      });
    }
  }
};

export const getAccountInvitations = async (userId: number) => {
  try {
    const { data } = await apiClient.get<AccountInvitationDto[]>(
      `/api/accountsadmin/c/account-invitation/get-by-user?userId=${userId}`
    );
    currentUserInvitations.value = data;
  } catch (e) {
    if ((e as AxiosError).code) {
      AppLogger.error({
        message: `Error getting account invitations`,
        exception: e as AxiosError,
      });
    }
  }
};

export const getCurrentUser = async (authType?: 'cc' | 'keycloak') => {
  try {
    let requestCurrentUser: { userData: JustSessionUserData } | undefined;
    if (authType === 'cc' && !isDemoMode.value) {
      requestCurrentUser = (
        await apiClient.get<{ userData: JustSessionUserData }>('/api/accountsadmin/c/authorization/check-authorized')
      ).data;
    }
    if ((requestCurrentUser as any)?.error && hasDemo.value) {
      requestCurrentUser = { userData: mockUser };
    }

    if (useCloudLogin.value && (requestCurrentUser?.userData?.internal || !requestCurrentUser?.userData?.accountId)) {
      goToSelectAccount();
    }

    const { data: organizationData } = await apiClient.get<OrganizationData>('/api/gateway/web/user');
    if (requestCurrentUser) {
      if (
        ccOptions.value?.registration?.userNeedToSpecifyCountryIsoCode &&
        !requestCurrentUser.userData?.countryIsoCode &&
        !currentUser.value?.countryIsoCode
      ) {
        window.location.href = '/c/select-country';
      }
      if (requestCurrentUser.userData.userId) {
        await getAllowedAccounts(requestCurrentUser.userData.userId);

        const isCurrentAccountAllowed = availableAccountsToChange()?.find(
          account => account.id === requestCurrentUser.userData.accountId
        );
        if (
          (!availableAccountsToChange()?.length || !isCurrentAccountAllowed) &&
          !hasDemo.value &&
          useCloudLogin.value
        ) {
          goToSelectAccount();
        }
        await getAccountInvitations(requestCurrentUser.userData.userId);
      }
      window.dataLayer?.push({ userId: `${requestCurrentUser.userData.userId}` });
      setCurrentUser({ ...requestCurrentUser.userData, ...organizationData });

      /* CPT-2771 may be return
      requestCurrentUser.userData?.language &&
        localize.setLocale(
          requestCurrentUser.userData?.language === 'EN'
            ? 'eng'
            : (requestCurrentUser.userData?.language ?? (isTovie ? 'eng' : 'ru')).toLowerCase()
        );*/
      return { ...requestCurrentUser.userData, ...organizationData };
    }
    setCurrentUser({ ...organizationData });
    return { ...organizationData };
  } catch (e) {
    console.log(e);
  }
};

export const pollCurrentUserForPaymentRequests = async () => {
  const { data } = await apiClient.get('/api/gateway/web/billing/status');
  return data as PaymentPollResponse;
};

export const dropCurrentUser = () => {
  currentUser.value = undefined;
};

export const getCurrentAccountData = () => {
  return currentUserAllowedAccounts.value?.find(data => data.id === currentUser.value?.accountId);
};

export const availableAccountsToChange = () => {
  return currentUserAllowedAccounts.value?.filter(account => account.availableToSelect);
};
