var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AppTemplatesApi, AppsApi, ConversationsApi, FilesApi, } from '../../generated/AppsAdapter';
class AppsAdapterService {
    constructor(axios) {
        this.appTemplatesApi = new AppTemplatesApi({}, AppsAdapterService.BASE_PATH, axios);
        this.appsApi = new AppsApi({}, AppsAdapterService.BASE_PATH, axios);
        this.filesApi = new FilesApi({}, AppsAdapterService.BASE_PATH, axios);
        this.conversationsApi = new ConversationsApi({}, AppsAdapterService.BASE_PATH, axios);
        this.telegramAppTemplatesApi = new AppTemplatesApi({}, `${AppsAdapterService.BASE_PATH}/direct`, axios);
    }
    getTelegramTemplatesForLocale(locale) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.telegramAppTemplatesApi.findTemplates(locale === 'eng' ? 'en' : undefined, // Get Russian templates by default
            'telegram');
        });
    }
    findFavoriteApps() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.appsApi.findFavoriteApps();
        });
    }
    changeAppFavoriteStatus(appId, appChangeFavoriteStatusRequest, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.appsApi.changeAppFavoriteStatus(appId, appChangeFavoriteStatusRequest, options);
        });
    }
    createConversation(reqBody, async, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.createConversation(reqBody, async, {
                abortController,
            });
        });
    }
    getTemplatesForLocale() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.appTemplatesApi.findTemplates();
        });
    }
    getUserAgents() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.appsApi.findFavoriteApps();
        });
    }
    getMainChat() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.findAssistant();
        });
    }
    createUserAgent(config, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.appsApi.createApp(config, { abortController });
        });
    }
    prevalidateApp(config, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.appsApi.prevalidateApp(config, { abortController });
        });
    }
    createUserConversation(reqBody, async, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.createConversation(reqBody, async, {
                abortController,
            });
        });
    }
    getUserChats(params, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            const { hasUnreadChanges, orderBy, sort } = params || {};
            return this.conversationsApi.findConversations(hasUnreadChanges, orderBy, sort, {
                abortController,
            });
        });
    }
    getUserChat(chatId, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.findConversationById(chatId, {
                abortController,
            });
        });
    }
    clearUnreadChanges(conversationId, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.clearUnreadChanges(conversationId, {
                abortController,
            });
        });
    }
    updateUserChatApp(chatId, updateData, async, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.updateConversationApp(chatId, updateData, async, {
                abortController,
            });
        });
    }
    deleteUserChat(chatId, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.deleteConversationById(chatId, {
                abortController,
            });
        });
    }
    renameUserChat(chatId, newName, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.updateConversation(chatId, { name: newName }, {
                abortController,
            });
        });
    }
    sendMessageToChat(chatId, message, options, async) {
        return __awaiter(this, void 0, void 0, function* () {
            const { text, file, fileId } = message;
            return this.conversationsApi.sendMessage(chatId, async, file, fileId, text, options);
        });
    }
    deleteMessage(chatId, messageId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.deleteMessage(chatId, messageId);
        });
    }
    cancelMessageProcessing(chatId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.cancelMessageProcessing(chatId);
        });
    }
    getChatHistory(chatId, oldestMessageTimestamp) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.findConversationHistory(chatId, 20, oldestMessageTimestamp);
        });
    }
    uploadFile(file, abortController, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.filesApi.uploadFile(file, Object.assign({ abortController }, options));
        });
    }
    clearChatHistory(chatId, abortController) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.conversationsApi.resetConversation(chatId, { abortController });
        });
    }
}
AppsAdapterService.BASE_PATH = '';
export default AppsAdapterService;
