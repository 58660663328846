import React, { memo, useEffect, useMemo, useState } from 'react';

import { useTranslation, Tabs } from '@just-ai/just-ui';
import { useLocation } from 'react-router';

import { isTovie } from '../../isTovie';
import { ContentContainer } from '../../pages/FullContainer/ContentContainer';
import { MobileHeader } from '../../pages/Main/MobileHeader';
import { goToAccount, goToAccountAnalytics, routes } from '../../routes';
import { AnalyticsTab } from './tabs/AnalyticsTab';
import { MainTab } from './tabs/MainTab';

export type JGuardKeyStatus = {
  keyStatus: 'off' | 'on' | 'error';
  emailSent: boolean;
};

const TabsTypes = {
  MAIN: 'main',
  ANALYTICS: 'analytics',
};

const AccountPage = memo(function AccountPage() {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(TabsTypes.MAIN);

  const location = useLocation();

  const tabs = useMemo(
    () => [
      {
        value: TabsTypes.MAIN,
        name: t('AccountPage:MainTabs:Main'),
      },
      {
        value: TabsTypes.ANALYTICS,
        name: t('AccountPage:MainTabs:Analytics'),
      },
    ],
    [t]
  );

  const changeTabHandler = (value: string) => {
    if (value === TabsTypes.MAIN) {
      goToAccount();
    } else {
      goToAccountAnalytics();
    }
    setCurrentTab(value);
  };

  useEffect(() => {
    if (location.pathname === routes.account) {
      return setCurrentTab(TabsTypes.MAIN);
    }
    if (location.pathname === routes.analytics) {
      return setCurrentTab(TabsTypes.ANALYTICS);
    }
  }, [location.pathname]);

  return (
    <ContentContainer withLicense={!isTovie}>
      <MobileHeader pageName={t('account')} />
      <div className='p-5 h-full flex flex-column'>
        <h1 className='d-none d-sm-block mb-5'>{t('account')}</h1>
        <Tabs tabs={tabs} activeTab={currentTab} onChange={changeTabHandler} className='mb-5' />
        {currentTab === TabsTypes.MAIN ? <MainTab /> : null}
        {currentTab === TabsTypes.ANALYTICS ? <AnalyticsTab /> : null}
      </div>
    </ContentContainer>
  );
});

export default AccountPage;
