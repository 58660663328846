import React, { ReactNode, useCallback } from 'react';

import { Button } from '@just-ai/just-ui';
import cn from 'classnames';
import { availableAccountsToChange } from 'models/currentUser';

import { useCloudLogin, appHasSystemFeature } from '../../api/cc.api';
import { useAppContext, useSidebarActions } from '../../contexts/appContext';
import { isAlpha } from '../../isAlpha';
import { guideTourEvent$ } from '../../modules/GuideTourStepper/guideTourEvents';
import { goToMain } from '../../routes';
import { Conversation } from '../../types/chat';
import { isMobile } from '../../utils/app/common';
import { ConversationComponent } from '../Chatbar/components/Conversation';
import { hideAgentSettingsMobileToggle } from '../Settings/AgentSettingsSidebar';
import { CloseSidebarButton } from './components/OpenCloseButton';
import { SelectAccountButton } from './components/SelectAccountButton';
import { LogoComponent } from './LogoComponent';
import styles from './style.module.scss';

const Sidebar = <Type,>({
  isOpen,
  addItemButtonTitle,
  side,
  items,
  itemComponent,
  footerComponent,
  toggleOpen,
}: Props<Type>) => {
  const {
    state: { lightMode },
  } = useAppContext();
  const [toggleSidebar] = useSidebarActions();

  const openAssistantCreationModal = useCallback(() => {
    guideTourEvent$.next('GoToMainFromSidebarButton');
    if (isMobile()) {
      hideAgentSettingsMobileToggle();
      toggleSidebar();
    }
    goToMain();
  }, [toggleSidebar]);

  const mainChat = (items as unknown[] as Conversation[]).find(conv => conv?.app?.template === 'main');
  const availableAccounts = availableAccountsToChange();

  return (
    <div
      className={cn(styles.sidebar, {
        [styles.sideBarOpen]: isOpen,
      })}
    >
      <div className={cn('flex align-items-center justify-between gap-16', styles.sidebar__header)}>
        <div className='flex gap-16'>
          <div className='flex d-sm-none'>
            <CloseSidebarButton onClick={toggleOpen} side={side} />
          </div>
          <LogoComponent />
        </div>
      </div>
      <div className={cn(`${lightMode}`, styles.sidebar__wrapper)}>
        {useCloudLogin.value && availableAccounts && availableAccounts.length > 1 && <SelectAccountButton />}
        <div className='flex items-center padding-bottom-0x padding-right-16 padding-top-16 padding-left-16'>
          <Button
            className={cn('flex w-full user-select-none', styles.sidebar__btnCreate)}
            onClick={openAssistantCreationModal}
            iconRight='farBars'
            color='primary'
            size='xl'
            data-test-id='GoToChooseNewTemplate'
          >
            {addItemButtonTitle}
          </Button>
        </div>
        {!!mainChat && !isAlpha && appHasSystemFeature('assistant') && (
          <div className='flex items-center padding-bottom-0x padding-right-16 padding-top-16 padding-left-16'>
            <ConversationComponent
              key={mainChat.id}
              conversationId={mainChat.id}
              conversationTemplate={mainChat.app.template}
              conversationName={mainChat.name}
              conversationStatus={mainChat.status}
              conversationMessageIsStreaming={mainChat.messageIsStreaming}
              conversationHasUnreadChanges={mainChat.hasUnreadChanges}
              conversationIcon={mainChat.config?.info?.icon}
              toggleSidebar={toggleSidebar}
            />
          </div>
        )}
        <div className='padding-16 padding-right-10 flex flex-col flex-grow-1 overflow-scroll'>
          {items?.length > 0 && itemComponent}
        </div>
        {footerComponent}
      </div>
    </div>
  );
};

interface Props<Type> {
  isOpen: boolean;
  addItemButtonTitle: string;
  side: 'left' | 'right';
  items: Type[];
  itemComponent: ReactNode;
  footerComponent?: ReactNode;
  toggleOpen: () => void;
}

export default Sidebar;
