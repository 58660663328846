import React, { useCallback, useEffect, useLayoutEffect } from 'react';

import { Spinner } from '@just-ai/just-ui';
import { registerPreloadedIcons } from '@just-ai/just-ui/dist/Icon';
import { Chat } from 'components/Chat/Chat';
import { LoadingContainer } from 'components/LoadingContainer';
import { UseAuthLoader } from 'components/UseCCLoader';
import { TelegramContextProvider } from 'contexts/TelegramContextProvider';
import { Page403 } from 'pages/Page403';
import TagManager from 'react-gtm-module';
import { Redirect } from 'react-router';
import { Route, Router, Switch } from 'react-router-dom';
import { getGoogleTag, processUtmTags } from 'utils/app/common';

import { appOptions, useCloudLogin, useKeycloakLogin, userKeycloakAuthHeaderSet } from './api/cc.api';
import appHistory from './appHistory';
import AccountPage from './components/Account';
import CorporatePage from './components/Corporate';
import { Login } from './components/Login';
import JGuardPanel from './components/TopPanel/JGuardPanel';
import MemoAssistantTour from './components/Tour';
import { isTovie } from './isTovie';
import localize, { engT, t } from './localization';
import { jGuardFailedToProtect } from './models/conversations/signals';
import { utmTagsList } from './models/currentUser';
import { creatingNewAgentFromFavorite } from './models/favourite/favourite.api';
import { FullContainer } from './pages/FullContainer';
import { Main } from './pages/Main';
import { TelegramTemplatesList } from './pages/TelegramTemplatesList';
import './App.scss';
import { routes, telegramRoutes } from './routes';

import './styles/globals.css';
import { useSignalEffect } from '@preact/signals-react';

registerPreloadedIcons([
  'faCircleNotch',
  'faPause',
  'faPlay',
  'farPlus',
  'faPen',
  'faJustSelect',
  'faGripVertical',
  'farPodcast',
  'farTrashAlt',
]);

function App() {
  useEffect(() => {
    const gtmId = getGoogleTag();
    utmTagsList.value = processUtmTags(appHistory.location.search);
    if (!gtmId) return;
    const tagManagerArgs = {
      gtmId: gtmId,
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  useSignalEffect(() => {
    localize.setConfigVariables({
      productName: {
        eng: appOptions.value?.product?.name || engT('defaultProductName'),
        ru: appOptions.value?.product?.name || t('defaultProductName'),
      },
      dataGuardName: {
        eng: engT('dataGuardProductName'),
        ru: t('dataGuardProductName'),
      },
      supportMail: isTovie
        ? {
            eng: engT('supportEmail:tovie'),
            ru: engT('supportEmail:tovie'),
          }
        : {
            eng: engT('supportEmail'),
            ru: engT('supportEmail'),
          },
    });
  });

  return (
    <Router history={appHistory}>
      <Switch>
        <Route path={telegramRoutes.base} exact>
          <TelegramContextProvider>
            <Route path={telegramRoutes.telegramDetail} exact component={() => <>telegramDetail</>} />
            <Route path={telegramRoutes.telegram} exact component={TelegramTemplatesList} />
          </TelegramContextProvider>
        </Route>
        <Route path={Object.values(routes)} exact>
          <UseAuthLoader>
            {useKeycloakLogin.value && !userKeycloakAuthHeaderSet.value ? (
              <Spinner />
            ) : (
              <FullContainer>
                <Route
                  path={[routes.main, routes.conversation, routes.account, routes.corporate, routes.analytics]}
                  exact
                >
                  {jGuardFailedToProtect.value && <JGuardPanel />}
                  <LoadingContainer>
                    {creatingNewAgentFromFavorite.value && <Spinner />}
                    <MemoAssistantTour />
                    <Switch>
                      <Route path={routes.conversation} exact component={Chat} />
                      <Route path={routes.main} exact component={Main} />
                      <Route path={[routes.account, routes.analytics]} component={AccountPage} />
                      <Route path={routes.corporate} exact component={CorporatePage} />
                    </Switch>
                  </LoadingContainer>
                </Route>
                <Route
                  path={routes.login}
                  exact
                  component={useCloudLogin.value ? () => <Redirect to={routes.main} /> : Login}
                />
              </FullContainer>
            )}
          </UseAuthLoader>
        </Route>
        <UseAuthLoader innerLoadingDefaultState={false}>
          <Route path='/403' exact component={Page403} />
        </UseAuthLoader>
        <Route path='*'>
          <Redirect to={routes.main} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
