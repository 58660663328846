import React, { KeyboardEvent, MutableRefObject, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Button, IconButton, Spinner, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';

import { isDemoMode } from '../../api/client';
import AppContext from '../../contexts/appContext';
import { showSignupModal } from '../../hooks/showSignup';
import { isX5 } from '../../isX5';
import { isRequestsLimitExceeded } from '../../models/conversations';
import { templates } from '../../models/templates';
import { guideTourEvent$ } from '../../modules/GuideTourStepper/guideTourEvents';
import { Conversation, FileMessagePart, Message } from '../../types/chat';
import { createDefaultMsg } from '../../utils/app/conversation';
import Attachments from './Attachments';
import { MAX_FILE_SIZE_CHAT } from './consts';
import { NoMoreThen3SyncRequestsTextInform } from './NoMoreThen3SyncRequestsTextInform';
import {
  appHasUnsavedChanges,
  settingsModalIsOpenedFromChat,
  showModalFromChatInput,
} from './signals/ChatUpdateSignal';
import styles from './style.module.scss';

const MAX_FILES = 1;

interface Props {
  selectedConversation: Conversation;
  onSend: (message: Message) => void;
  onScrollDownClick: () => void;
  onCancelSend: () => void;
  textareaRef?: MutableRefObject<HTMLTextAreaElement | null>;
  showScrollDownButton: boolean;
  requestWasStopped?: boolean;
}

export const ChatInput = ({
  onSend,
  onScrollDownClick,
  textareaRef,
  showScrollDownButton,
  onCancelSend,
  selectedConversation,
  requestWasStopped,
}: Props) => {
  const { t } = useTranslation();
  const { templatesMap } = templates.value;
  const {
    state: { lightMode },
    addAlert,
  } = useContext(AppContext);

  const [content, setContent] = useState('');
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const [files, setFiles] = useState<File[]>([]);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (isDemoMode.value) return;
    const value = e.target.value;

    setContent(value);
  };

  const handleUpdateSettingsClick = () => {
    showModalFromChatInput.value = true;
    settingsModalIsOpenedFromChat.value = true;
  };

  const handleSend = () => {
    if (selectedConversation.messageIsStreaming || isRequestsLimitExceeded.value) {
      return;
    }
    guideTourEvent$.next(`ChatInput:send:${selectedConversation?.config.template || 'Empty'}`);

    if (!content && !files.length) {
      alert(t('Please enter a message'));
      return;
    }

    if (appHasUnsavedChanges.value) {
      handleUpdateSettingsClick();
      return;
    }

    onSend(
      createDefaultMsg(
        [{ type: 'text', text: content }, ...files.map(file => ({ type: 'file', file } as FileMessagePart))],
        selectedConversation.id,
        'request'
      )
    );
    setContent('');
    setFiles([]);
    if (inputRef.current) {
      inputRef.current.value = '';
    }

    if (window.innerWidth < 640 && textareaRef && textareaRef.current) {
      textareaRef.current.blur();
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length && event.target.files.length > MAX_FILES) {
      if (inputRef.current) {
        inputRef.current.files = null;
        inputRef.current.value = '';
      }
      return alert(t('maxFilesInfo', MAX_FILES));
    }
    const filesArr = Array.from(event.target.files || []);
    if (filesArr.some(file => file.size / 1024 / 1024 > MAX_FILE_SIZE_CHAT)) {
      if (inputRef.current) {
        inputRef.current.files = null;
        inputRef.current.value = '';
      }
      return addAlert(t('fileSizeError__param', { size: MAX_FILE_SIZE_CHAT }), 'info');
    }
    setFiles(filesArr);
  };

  const handleFileRemove = (file: File) => {
    setFiles(files => files.filter(localFile => file.name !== localFile.name));
    if (inputRef.current) {
      inputRef.current.files = null;
      inputRef.current.value = '';
    }
  };

  const handleStopConversation = () => {
    onCancelSend();
  };

  const isMobile = () => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i;
    return mobileRegex.test(userAgent);
  };

  const hideAttachButton = useMemo(
    () => templatesMap[selectedConversation.app.template]?.clientFeatures?.fileAttachment === false,
    [selectedConversation, templatesMap]
  );

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = 'inherit';
      requestAnimationFrame(() => {
        if (textareaRef.current) textareaRef.current.style.height = `${textareaRef.current?.scrollHeight}px`;
      });
    }
  }, [content, textareaRef]);

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !isTyping && !isMobile() && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div
      className={styles.chat__newMessageBlock}
      onClick={() => {
        if (appHasUnsavedChanges.value) handleUpdateSettingsClick();
      }}
    >
      <div className={cn('flex flex-col gap-3 relative', styles.chat__newMessageBlockWrapper)}>
        <div className={styles.chat__additionalActionContainer}>
          {showScrollDownButton && (
            <IconButton
              className={cn(styles.chat__inputBtnScrollDown)}
              onClick={onScrollDownClick}
              name='farChevronDown'
              flat
              outline={true}
              color='none'
            />
          )}
          {isRequestsLimitExceeded.value ? <NoMoreThen3SyncRequestsTextInform /> : null}
          {selectedConversation.messageIsStreaming && !requestWasStopped && selectedConversation.history.length > 0 && (
            <Button
              iconLeft='farBan'
              className={cn(styles.chat__btnStop, 'bg-hover-color-gray-200')}
              onClick={handleStopConversation}
              outline={true}
              color='secondary'
              size={isMobile() ? 'md' : 'sm'}
              data-test-id='Chat.CancelSend'
            >
              {t('ChatInput:StopGenerating')}
            </Button>
          )}
        </div>
        <div
          data-test-id={`NewMessageInputWrapper:${selectedConversation.app.template || 'Empty'}`}
          className={cn('flex w-full flex-grow flex-col', lightMode, styles.chat__newMessageInputWrapper)}
        >
          {files?.length > 0 && <Attachments files={files} handleFileRemove={handleFileRemove} />}
          <textarea
            ref={textareaRef}
            data-test-id='NewMessageInput'
            className={cn('w-full', lightMode, styles.chat__newMessageTextarea)}
            style={{
              resize: 'none',
              bottom: `${textareaRef?.current?.scrollHeight}px`,
              maxHeight: '200px',
              overflowY: `scroll`,
            }}
            placeholder={t('typeMessage')}
            value={content}
            onCompositionStart={() => setIsTyping(true)}
            onCompositionEnd={() => setIsTyping(false)}
            onClick={() => isDemoMode.value && showSignupModal(true)}
            rows={1}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onFocus={() => guideTourEvent$.next(`ChatInput:focus:${selectedConversation?.app.template || 'Empty'}`)}
          />

          <div className={cn(styles.chat__inputBtnWrapper)}>
            {!isX5 && !hideAttachButton && !isDemoMode.value && (
              <>
                <input type='file' className='display-none' ref={inputRef} onChange={handleFileUpload} />
                <IconButton
                  data-test-id='NewMessageInput:file_btn'
                  square
                  onClick={() => inputRef.current?.click()}
                  name='farPaperclip'
                  flat
                  color='secondary'
                />
              </>
            )}
            <div className='divider' />
            {selectedConversation.messageIsStreaming ? (
              <Spinner size='md' inline style={{ margin: '0 12px' }} />
            ) : (
              <IconButton
                square
                data-test-id='NewMessageInput:file_send'
                onClick={handleSend}
                name='fasPaperPlane'
                flat
                color='primary'
                disabled={isRequestsLimitExceeded.value || (content?.length === 0 && files?.length === 0)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
